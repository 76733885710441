/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import bind from 'bind-decorator';
import {
  Input,
  Divider,
  Icon,
  Button,
  Header,
  Dropdown,
  Pagination,
  Modal,
  Table,
  Dimmer,
  Loader,
  Message,
  List,
} from 'semantic-ui-react';
import pluralize from 'pluralize';
import { v4 as uuid } from 'uuid';
import User from '@/models/user';
import Organization from '@/models/organization';
import { ModalProvider, ModalConsumer, ModalRoot } from '../utils/ModalContext';
import { hickoryBlue } from '../../styles/palette';
import UserModal from './UserModal';
import PeopleFinder from './PeopleFinder';
import GroupSelectionModal from './GroupSelectionModal';
import UserTagModal from './UserTagModal';
import UserActionModal from './UserActionModal';

const s = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
  },
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  search: {
    margin: '1em 1em .5em 1em',
  },
  selection: {
    backgroundColor: hickoryBlue,
    color: 'white',
  },
  groupCell: {
    cursor: 'pointer',
  },
};

class PeopleControlBar extends Component {
  static propTypes = {
    userCount: PropTypes.number,
    style: PropTypes.object,
    setSearch: PropTypes.func,
    setPage: PropTypes.func,
    page: PropTypes.number,
    perPage: PropTypes.number,
    setPerPage: PropTypes.func,
    selectedUserCount: PropTypes.number,
    selectedUsers: PropTypes.array,
    updateUsers: PropTypes.func,
    refreshTags: PropTypes.func,
    selectNone: PropTypes.func,
    addUsersToGroup: PropTypes.func,
    assignedUsers: PropTypes.array,
    selectedGroup: PropTypes.object,
    history: PropTypes.object,
  };

  static defaultProps = {
    selectedUsers: [],
    addUsersToGroup: () => {},
  };

  constructor(props) {
    super(props);

    this.timer = null;
    this.state = {
      showModal: false,
      showGroupModal: false,
      showTagModal: false,
      currentAction: '',
      showProgressModal: false,
      showUserQuotaErrorModal: false,
    };
  }

  async componentDidMount() {
    const user = await User.me();
    const organization = await Organization.objects().get(user.organization_id);
    this.setState({
      organization,
    });
  }

  @bind
  search(e, { value }) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.setSearch(value);
    }, 300);
  }

  @bind
  handlePageChange(e, { activePage }) {
    this.props.setPage(activePage);
  }

  @bind
  handlePerPageChange(e, { value }) {
    this.props.setPerPage(value);
  }

  @bind
  toggleGroupModal() {
    this.setState({
      showGroupModal: !this.state.showGroupModal,
    });
  }

  @bind
  buildUserModal({ hideModal, ...otherProps }) {
    return (
      <UserModal
        hideModal={hideModal}
        create={otherProps.create}
        updateUsers={otherProps.updateUsers}
        selectedGroup={otherProps.selectedGroup}
      />
    );
  }

  @bind
  buildPeopleFinderModal({ hideModal, ...otherProps }) {
    return (
      <PeopleFinder
        hideModal={hideModal}
        assignedUsers={this.props.assignedUsers}
        onSelect={(users) => {
          hideModal();
          this.props.addUsersToGroup(users);
        }}
        updateUsers={otherProps.updateUsers}
        selectedGroup={otherProps.selectedGroup}
      />
    );
  }

  @bind
  async addToGroup(groups = []) {
    const actions = [];
    if (groups.length) {
      this.props.selectedUsers.forEach((user) => {
        groups.map((groupId) => user.assignToGroup(groupId));
      });
    }
    await Promise.all(actions);
    this.toggleGroupModal();
    this.props.updateUsers();
    this.props.selectNone();
  }

  @bind
  async handleAction() {
    const actions = [];
    switch (this.state.currentAction) {
      case 'Invite':
        this.props.selectedUsers.forEach((user) => {
          actions.push(user.sendInvite());
        });
        break;
      case 'Deactivate':
        this.props.selectedUsers.forEach((user) => {
          actions.push(User.objects().update(user.id, { is_active: false }));
        });
        break;
      case 'Delete':
        this.props.selectedUsers.forEach((user) => {
          actions.push(User.objects().update(user.id, { is_active: false, deleted: true }));
        });
        break;
      default:
        break;
    }
    await Promise.all(actions);
    this.toggleModal();
    this.props.updateUsers();
    this.props.selectNone();
  }

  @bind
  async toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  @bind
  async toggleTagModal() {
    this.setState({ showTagModal: !this.state.showTagModal });
  }

  @bind
  async tagUsers(tags) {
    const actions = [];
    this.props.selectedUsers.forEach((user) => {
      actions.push(User.objects().update(user.id, { tags: [...new Set(user.tags.concat(tags))] }));
    });
    await Promise.all(actions);
    this.toggleTagModal();
    this.props.refreshTags();
    this.props.updateUsers();
  }

  @bind
  async removeSelectedUsersFromGroup() {
    this.setState({
      showRemoveFromGroupModal: false,
      showProgressModal: true,
    });
    const actions = [];
    this.props.selectedUsers.forEach((user) => {
      actions.push(user.removeFromGroup(this.props.selectedGroup.id));
    });
    await Promise.all(actions);
    this.props.updateUsers();
    this.props.selectNone();
    this.setState({ showProgressModal: false });
  }

  @bind
  async removeSelectedUsersFromAllGroups() {
    this.setState({
      showRemoveAllGroupsWarning: false,
      showProgressModal: true,
    });
    const actions = [];
    this.props.selectedUsers.forEach((user) => {
      actions.push(user.removeFromAllGroups());
    });
    await Promise.all(actions);
    this.props.updateUsers();
    this.props.selectNone();
    this.setState({ showProgressModal: false });
  }

  @bind
  async removeAllTagsFromSelectedUsers() {
    this.setState({
      showRemoveAllTagsWarning: false,
      showProgressModal: true,
    });
    const actions = [];
    this.props.selectedUsers.forEach((user) => {
      actions.push(User.objects().update(user.id, { tags: [] }));
    });
    await Promise.all(actions);
    this.props.updateUsers();
    this.props.selectNone();
    this.setState({ showProgressModal: false });
  }

  @bind
  attemptUserCreation(showModal) {
    const { organization } = this.state;
    const overQuota = organization.total_active_users >= organization.hard_limit;
    if (overQuota) {
      this.setState({
        showUserQuotaErrorModal: true,
      });
    } else {
      showModal(this.buildUserModal, {
        create: true,
        updateUsers: this.props.updateUsers,
        selectedGroup: this.props.selectedGroup,
      });
    }
  }

  @bind
  attemptBulkUserCreation() {
    const { organization } = this.state;
    const overQuota = organization.total_active_users >= organization.hard_limit;
    if (overQuota) {
      this.setState({
        showUserQuotaErrorModal: true,
      });
    } else {
      // <Link style={{ color: 'black' }} to="/app/v2/uploader">Bulk Upload Users</Link>
      this.props.history.push('/app/v2/uploader');
    }
  }

  render() {
    const { selectedUserCount } = this.props;
    const totalPages = Math.ceil(
      this.props.userCount / (this.props.perPage || this.props.userCount),
    );
    const perPageOptions = [
      { text: '50', value: 50 },
      { text: '100', value: 100 },
      { text: '200', value: 200 },
      { text: '500', value: 500 },
    ];
    const userActions = {
      Invite: this.inviteUsers,
      Deactivate: this.deactivateUsers,
      Delete: this.deleteUsers,
    };
    const selection = !!selectedUserCount;
    const containerStyle = [s.container];
    if (selection) {
      containerStyle.push(s.selection);
    }

    return (
      <div style={this.props.style}>
        {this.props.setSearch ? (
          <div>
            <Input
              style={s.search}
              icon="search"
              iconPosition="left"
              placeholder="Search people by name or email..."
              onChange={this.search}
              size="huge"
              transparent
              fluid
            />
            <Divider fitted style={{ margin: '0em 1em' }} />
          </div>
        ) : (
          <p />
        )}
        <div style={containerStyle}>
          <div style={[s.container, { minWidth: '200px', justifyContent: 'start', padding: 0 }]}>
            <Dropdown
              options={perPageOptions}
              compact
              inline
              value={this.props.perPage}
              onChange={this.handlePerPageChange}
            />
            per page
          </div>
          <div style={s.column}>
            <Header as="h3" inverted={selection} style={{ marginTop: 0, marginBottom: 10 }}>
              {selection
                ? `${selectedUserCount} ${pluralize(
                  'PERSON',
                  selectedUserCount,
                )} SELECTED`
                : `${this.props.userCount} ${pluralize('PERSON', this.props.userCount)} FOUND`}
            </Header>
            <Pagination
              activePage={this.props.page}
              totalPages={totalPages}
              onPageChange={this.handlePageChange}
              size="small"
            />
          </div>
          {selection ? (
            <Button.Group inverted style={{ minWidth: '200px', justifyContent: 'end' }}>
              <Button inverted onClick={this.toggleTagModal}>
                Tag
                {' '}
                <Icon name="tag" style={{ marginLeft: '5px' }} />
              </Button>
              <UserTagModal
                showModal={this.state.showTagModal}
                toggleModal={this.toggleTagModal}
                tagUsers={this.tagUsers}
              />
              <Dropdown text="More..." className="button inverted" simple>
                <Dropdown.Menu>
                  <GroupSelectionModal
                    closeModal={this.toggleGroupModal}
                    showModal={this.state.showGroupModal}
                    handleGroups={this.addToGroup}
                  />
                  {this.props.selectedGroup ? (
                    <Dropdown.Item
                      onClick={() => {
                        this.setState({ showRemoveFromGroupModal: true });
                      }}
                    >
                      Remove from Group
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item onClick={this.toggleGroupModal}>Add to Group</Dropdown.Item>
                  )}
                  <UserActionModal
                    toggleModal={this.toggleModal}
                    showModal={this.state.showModal}
                    handleSave={this.handleAction}
                    selectedUsers={this.props.selectedUsers}
                    item={this.state.currentAction}
                  />
                  {Object.keys(userActions).map((item) => (
                    <Dropdown.Item
                      key={item}
                      onClick={() => {
                        this.setState(
                          {
                            currentAction: item,
                          },
                          this.toggleModal,
                        );
                      }}
                    >
                      {item}
                    </Dropdown.Item>
                  ))}
                  <Dropdown.Item color="red" onClick={() => this.setState({ showRemoveAllGroupsWarning: true })}>
                    Remove from all groups
                  </Dropdown.Item>
                  <Dropdown.Item color="red" onClick={() => this.setState({ showRemoveAllTagsWarning: true })}>
                    Remove all tags
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Button.Group>
          ) : (
            // Modal handling lifted from:
            // https://medium.com/@BogdanSoare/how-to-use-reacts-new-context-api-to-easily-manage-modals-2ae45c7def81
            <div>
              {this.props.selectedGroup ? (
                <ModalProvider>
                  <ModalRoot />
                  <ModalConsumer>
                    {({ showModal }) => (
                      <Dropdown text="Add Learner..." button className="green">
                        <Dropdown.Menu>
                          <Dropdown.Item
                            text="Create New User"
                            onClick={() => showModal(this.buildUserModal, {
                              create: true,
                              updateUsers: this.props.updateUsers,
                              selectedGroup: this.props.selectedGroup,
                            })}
                          />
                          <Dropdown.Item
                            text="Add Existing User"
                            onClick={() => {
                              showModal(this.buildPeopleFinderModal, {
                                updateUsers: this.props.updateUsers,
                                selectedGroup: this.props.selectedGroup,
                              });
                            }}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </ModalConsumer>
                </ModalProvider>
              ) : (
                <ModalProvider>
                  <ModalRoot />
                  <ModalConsumer>
                    {({ showModal }) => (
                      <Dropdown text="Create New..." button className="green">
                        <Dropdown.Menu>
                          <Dropdown.Item
                            text="Create Single User"
                            onClick={() => this.attemptUserCreation(showModal)}
                          />
                          <Dropdown.Item
                            text="Bulk Upload Users"
                            onClick={this.attemptBulkUserCreation}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </ModalConsumer>
                </ModalProvider>
              )}
            </div>
          )}
        </div>

        <Modal
          open={this.state.showUserQuotaErrorModal}
          onClose={() => {
            this.setState({ showUserQuotaErrorModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Quota Exceeded</Modal.Header>
          <Modal.Content>
            We&apos;re sorry, but you are currently over your quota for users and cannot add any more at this time.
            Please delete any unneeded users or contact us for more details on upgrading your plan.
          </Modal.Content>
          <Modal.Actions>
            <Button type="submit" className="green" onClick={() => this.setState({ showUserQuotaErrorModal: false })}>
              OK
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showRemoveFromGroupModal}
          style={{ width: '500px' }}
          closeIcon
          onClick={() => {
            this.setState({ showRemoveFromGroupModal: false });
          }}
        >
          <Modal.Header>Confirm Removal</Modal.Header>
          <Modal.Content scrolling>
            <div style={s.column}>
              <p>Are you sure you wish to remove the following users from this group?</p>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.selectedUsers.map((item) => (
                    <Table.Row key={item.id}>
                      <Table.Cell>{`${item.first_name} ${item.last_name}`}</Table.Cell>
                      <Table.Cell>{item.email}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="button"
              onClick={() => {
                this.setState({ showRemoveFromGroupModal: false });
              }}
            >
              Cancel
            </Button>
            <Button type="submit" className="red" onClick={this.removeSelectedUsersFromGroup}>
              Remove
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal open={this.state.showProgressModal} style={{ width: '500px' }}>
          <Modal.Header />
          <Modal.Content>
            <div style={s.column}>
              <Dimmer active inverted>
                <Loader inverted inline="centered">
                  Removing users from group{this.props.selectedGroup ? '' : 's'}...
                </Loader>
              </Dimmer>
            </div>
          </Modal.Content>
        </Modal>

        <Modal
          open={this.state.showRemoveAllGroupsWarning}
          style={{ width: '500px' }}
          closeIcon
          onClose={() => {
            this.setState({ showRemoveAllGroupsWarning: false });
          }}
        >
          <Modal.Header>
            {`Removing ${selectedUserCount} ${pluralize('user', selectedUserCount)} from ALL GROUPS`}
          </Modal.Header>
          <Modal.Content scrolling>
            <div style={s.column}>
              <p>Are you sure you wish to remove the following users from ALL GROUPS?</p>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>Groups</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.selectedUsers.slice(0, 10).map((item) => (
                    <Table.Row key={item.id}>
                      <Table.Cell>{`${item.first_name} ${item.last_name}`}</Table.Cell>
                      <Table.Cell>{item.email}</Table.Cell>
                      <Table.Cell>
                        <ExpandableCell
                          content={item.learning_groups}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              {selectedUserCount > 10
              && <Header as="h3" color="red">and {selectedUserCount - 10} more...</Header>}
              <Message warning>
                Warning: This action CANNOT be undone! All selected users will be removed from all
                groups they are a member of, and they will no longer be assigned lessons from those groups.
              </Message>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="button"
              onClick={() => {
                this.setState({ showRemoveAllGroupsWarning: false });
              }}
            >
              Cancel
            </Button>
            <Button type="submit" className="red" onClick={this.removeSelectedUsersFromAllGroups}>
              Remove
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          open={this.state.showRemoveAllTagsWarning}
          style={{ width: '500px' }}
          closeIcon
          onClose={() => {
            this.setState({ showRemoveAllTagsWarning: false });
          }}
        >
          <Modal.Header>
            {`Removing ALL TAGS from ${selectedUserCount} ${pluralize('user', selectedUserCount)}`}
          </Modal.Header>
          <Modal.Content scrolling>
            <div style={s.column}>
              <p>Are you sure you wish to remove ALL TAGS from the following users?</p>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>Tags</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.selectedUsers.map((item) => (
                    <Table.Row key={item.id}>
                      <Table.Cell>{`${item.first_name} ${item.last_name}`}</Table.Cell>
                      <Table.Cell>{item.email}</Table.Cell>
                      <Table.Cell>
                        <ExpandableCell
                          content={item.tags}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <Message warning>
                Warning: This action CANNOT be undone!
              </Message>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="button"
              onClick={() => {
                this.setState({ showRemoveAllTagsWarning: false });
              }}
            >
              Cancel
            </Button>
            <Button type="submit" className="red" onClick={this.removeAllTagsFromSelectedUsers}>
              Remove
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

class ExpandableCell extends Component {
  static propTypes = {
    content: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  render() {
    const content = this.state.expanded ? this.props.content : this.props.content.slice(0, 2);
    return (
      <List
        onClick={() => this.setState({ expanded: !this.state.expanded })}
        style={this.props.content.length > 2 ? s.groupCell : null}
      >
        {content.map((item, index) => {
          let expandable = false;
          if (!this.state.expanded && index === 1 && this.props.content.length > 2) {
            expandable = true;
          }
          return <List.Item key={uuid()}>{expandable ? `${item} ...` : item}</List.Item>;
        })}
      </List>
    );
  }
}

export default Radium(PeopleControlBar);
