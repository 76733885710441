import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bind from 'bind-decorator';

import { Entity } from 'draft-js';

import {
  Button,
  Divider,
  Icon,
  Modal,
} from 'semantic-ui-react';
import Radium from 'radium';
import Upload from '../Shared/Upload';

const MEGABYTE = 1048576;

const s = {
  cardEditorEmbed: {
    width: '60%',
    marginBottom: '2rem',
    display: 'inline-block',
  },
  embedContainer: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
  },
  moreInfo: {
    textAlign: 'center',
    position: 'relative',
    marginBottom: '2rem',
    fontSize: '9px',
  },
  moreInfoText: {
    width: '29%',
    height: '1px',
    top: '8px',
    background: '#dedeea',
  },
  formControl: {
    backgroundColor: 'white',
    border: '1px solid #ccc',
    padding: '0.8rem',
    fontSize: '1.3rem',
    height: '3rem',
    margin: '3px 0',
    cursor: 'auto',
    minWidth: '500px',
    minHeight: '100px',
    borderRadius: '6px',
  },
};

class ComposerMedia extends Component {
  static displayName = 'ComposerMedia';

  static defaultProps = {
    onSaveEmbed: () => {},
    onSaveImage: () => {},
    onSaveVideo: () => {},
    onRemove: () => {},
    lesson: null,
  };

  static propTypes = {
    onSaveEmbed: PropTypes.func,
    onSaveImage: PropTypes.func,
    onSaveVideo: PropTypes.func,
    onRemove: PropTypes.func,
    lesson: PropTypes.object,
    blockProps: PropTypes.object,
    block: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      embedCodePresave: '',
    };
  }

  updateEmbedCode = (event) => {
    this.setState({
      embedCodePresave: event.target.value,
    });
  };

  @bind
  async updateImage(files) {
    const { block } = this.props;
    const { onSaveImage, onRemove, lesson } = this.props.blockProps;
    if (!files) {
      onRemove(block.getKey());
    } else {
      const url = await lesson.uploadImage(files[0]);
      onSaveImage(block.getKey(), url);
    }
  }

  @bind
  async updateVideo(files) {
    const { block } = this.props;
    const { onSaveVideo, onRemove, lesson } = this.props.blockProps;
    if (!files) {
      onRemove(block.getKey());
    } else {
      const file = files[0];
      if (file.size >= 50 * MEGABYTE) {
        this.setState({
          showError: true,
          error: 'Selected file is greater than the maximum supported file size of 50MB.',
        });
        return;
      }
      const url = await lesson.uploadVideo(file);
      onSaveVideo(block.getKey(), url);
    }
  }

  render() {
    const { block } = this.props;
    const { onRemove, onSaveEmbed } = this.props.blockProps;

    if (block.getEntityAt(0) !== null) {
      const entity = Entity.get(block.getEntityAt(0));
      if (entity.data.type === 'embed') {
        if (entity.data.code === null) {
          return (
            <div
              style={s.cardEditorEmbed}
              data-offset-key={1}
              contentEditable={false}
            >
              <textarea
                style={s.formControl}
                rows="4"
                placeholder="Paste the embed code of your media here"
                onChange={this.updateEmbedCode}
                value={this.state.embedCodePresave}
              />
              <Button
                positive
                onClick={() => onSaveEmbed(block.getKey(), this.state.embedCodePresave)}
              >
                Add
              </Button>
              <Button
                positive
                onClick={() => onRemove(block.getKey())}
              >
                Cancel
              </Button>
            </div>
          );
        }
        return (
          <div
            style={s.cardEditorEmbed}
            data-offset-key={1}
            contentEditable={false}
          >
            <div
              style={s.embedContainer}
              dangerouslySetInnerHTML={{ __html: entity.data.code }}
            />
          </div>
        );
      } if (entity.data.type === 'image') {
        return (
          <div>
            <Upload
              maxSize={20}
              source={entity.data.image}
              accept="image/png, image/jpeg, image/gif, image/webp"
              updateFile={this.updateImage}
              description="an Image"
            />
            <div
              style={{
                color: 'red', textAlign: 'center', fontSize: '1.4rem', cursor: 'pointer',
              }}
              onClick={() => onRemove(block.getKey())}
            >
              (remove)
            </div>

          </div>
        );
      } if (entity.data.type === 'video') {
        return (
          <div>
            <Upload
              source={entity.data.image}
              accept="video/mp4, video/webm, video/quicktime"
              updateFile={this.updateVideo}
              description="a Video"
            />
            <div
              style={{
                color: 'red', textAlign: 'center', fontSize: '1.4rem', cursor: 'pointer',
              }}
              onClick={() => onRemove(block.getKey())}
            >
              (remove)
            </div>

            <Modal open={this.state.showError}>
              <Modal.Header>Error</Modal.Header>
              <Modal.Content image>
                <Modal.Description>
                  <p>
                    {this.state.error}
                  </p>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  content="Okay"
                  positive
                  onClick={() => {
                    this.setState({ showError: false });
                  }}
                />
              </Modal.Actions>
            </Modal>
          </div>
        );
      } if (entity.data.type === 'more-info') {
        return (
          <Divider horizontal>
            <span style={s.moreInfo}>
              More Info
              <Icon name="chevron down" />
            </span>
          </Divider>
        );
      }
      return <div />;
    }
    return <div />;
  }
}

export default Radium(ComposerMedia);
