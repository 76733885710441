import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Label, List } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';

const s = {
  groupCell: {
    cursor: 'pointer',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
  },
};

class ExpandableList extends Component {
  static propTypes = {
    content: PropTypes.array,
    remove: PropTypes.func,
    expanded: PropTypes.bool,
    toggleExpansion: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      content: props.content,
    };
  }

  render() {
    const content = this.props.expanded ? this.state.content : this.state.content.slice(0, 2);
    return (
      <List onClick={this.props.toggleExpansion} style={this.state.content.length > 2 ? s.groupCell : null}>
        {content.map((item, index) => {
          let expandable = false;
          if (!this.props.expanded && index === 1 && this.state.content.length > 2) {
            expandable = true;
          }
          if (this.props.remove) {
            return (
              <List.Item style={s.row} key={uuid()}>
                <Label style={{ whiteSpace: 'nowrap' }}>
                  {item}
                  <Icon
                    style={{ marginLeft: '10px', marginRight: '0' }}
                    name="remove circle"
                    onClick={async (e) => {
                      e.stopPropagation();
                      await this.props.remove(item);
                      const tempcontent = this.state.content;
                      tempcontent.splice(this.state.content.indexOf(item), 1);
                      this.setState({
                        content: tempcontent,
                      });
                    }}
                  />
                </Label>
                {expandable ? ' ...' : ''}
              </List.Item>
            );
          }
          return (
            <List.Item
              style={{ whiteSpace: 'nowrap' }}
              key={uuid()}
            >{expandable ? `${item} ...` : item}
            </List.Item>
          );
        })}
      </List>
    );
  }
}

export default ExpandableList;
