import { ModelBase } from './model';

class UserTag extends ModelBase {
  static namespace = 'user_tags';

  static path = '/api/v2/users/tags/';

  static cache = false;
}
export default UserTag.register();
