import { ModelBase } from './model';

class LessonTag extends ModelBase {
  static namespace = 'lesson_tags';

  static path = '/api/v2/lessons/tags/';

  static cache = false;
}
export default LessonTag.register();
