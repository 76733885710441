import ls from 'local-storage';
import { ModelBase } from '@/models/model';

const MAX_FILE_SIZE = 5242880; // 5 MB.
const CHUNK_SIZE = 1048576; // 1 MB

class Lesson extends ModelBase {
  static namespace = 'lesson';

  async new_revision() {
    const response = await fetch(`/api/v2/lessons/${this.id}/new_revision/`, {
      method: 'POST',
      credentials: 'same-origin',
      header: {
        Authorization: `Bearer ${ls('token.access')}`,
        Accept: 'application/json',
      },
    });
    const lesson = await response.json();
    return lesson;
  }

  async publish() {
    const response = await fetch(`/api/v2/lessons/${this.id}/publish/`, {
      method: 'POST',
      credentials: 'same-origin',
      header: {
        Authorization: `Bearer ${ls('token.access')}`,
        Accept: 'application/json',
      },
    });

    return response.status === 200;
  }

  async revert() {
    const response = await fetch(`/api/v2/lessons/${this.id}/revert/`, {
      method: 'POST',
      credentials: 'same-origin',
      header: {
        Authorization: `Bearer ${ls('token.access')}`,
        Accept: 'application/json',
      },
    });

    return response.status === 200;
  }

  async disableReviews() {
    const response = await fetch(`/api/v2/lessons/${this.id}/disable_reviews/`, {
      method: 'POST',
      credentials: 'same-origin',
      header: {
        Authorization: `Bearer ${ls('token.access')}`,
        Accept: 'application/json',
      },
    });

    return response.status === 200;
  }

  async uploadImage(file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', 'images');
    const response = await fetch(`/api/v2/lessons/${this.id}/upload/`, {
      method: 'POST',
      credentials: 'same-origin',
      header: {
        Authorization: `Bearer ${ls('token.access')}`,
        Accept: 'application/json',
      },
      body: formData,
    });
    const data = await response.json();
    return data.url;
  }

  async uploadVideo(file, progressCallback) {
    if (file.size >= MAX_FILE_SIZE) {
      // if the file is greater than (or equal to) MAX_FILE_SIZE
      // then we will have to upload it in chunks

      const fileParts = file.name.split('.');
      const ext = fileParts[fileParts.length - 1];

      const chunks = Math.ceil(file.size / CHUNK_SIZE);

      for (let i = 0; i !== chunks; i++) {
        const chunk = file.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);
        const formData = new FormData();

        formData.append('chunk', chunk);
        formData.append('key', i);
        // eslint-disable-next-line no-await-in-loop
        const response = await fetch(`/api/v2/lessons/${this.id}/chunked_upload/`, {
          method: 'POST',
          credentials: 'same-origin',
          header: {
            Authorization: `Bearer ${ls('token.access')}`,
            Accept: 'application/json',
          },
          body: formData,
        });
        if (response.status !== 200) {
          console.error('Oh No!!!');
        }
        if (progressCallback) {
          progressCallback(i / chunks);
        }
      }

      // now complete the chunked upload...
      const formData = new FormData();
      formData.append('keys', chunks);
      formData.append('ext', ext);
      formData.append('type', 'videos');
      const response = await fetch(`/api/v2/lessons/${this.id}/complete_chunked_upload/`, {
        method: 'POST',
        credentials: 'same-origin',
        header: {
          Authorization: `Bearer ${ls('token.access')}`,
          Accept: 'application/json',
        },
        body: formData,
      });
      if (progressCallback) {
        progressCallback(1);
      }
      const data = await response.json();
      return data.url;
    }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', 'videos');
    const response = await fetch(`/api/v2/lessons/${this.id}/upload/`, {
      method: 'POST',
      credentials: 'same-origin',
      header: {
        Authorization: `Bearer ${ls('token.access')}`,
        Accept: 'application/json',
      },
      body: formData,
    });
    const data = await response.json();
    return data.url;
  }

  async uploadSCORMContent(data) {
    const formData = new FormData();
    formData.append('file', data);
    const response = await fetch(`/api/v2/scorm/${this.id}`, {
      method: 'POST',
      credentials: 'same-origin',
      header: {
        Authorization: `Bearer ${ls('token.access')}`,
        Accept: 'application/json',
      },
      body: formData,
    });
    const result = await response.json();
    return result.data;
  }

  async uploadPDF(file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', 'pdfs');
    const response = await fetch(`/api/v2/lessons/${this.id}/upload/`, {
      method: 'POST',
      credentials: 'same-origin',
      header: {
        Authorization: `Bearer ${ls('token.access')}`,
        Accept: 'application/json',
      },
      body: formData,
    });
    const data = await response.json();
    return data.url;
  }

  async markAsComplete(duration) {
    const formData = new FormData();
    formData.append('duration', duration);
    const response = await fetch(`/api/v2/lessons/${this.id}/complete/`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${ls('token.access')}`,
        Accept: 'application/json',
      },
      body: formData,
    });
    if (response.status === 200) {
      return true;
    }
    return false;
  }

  async scoreLesson(nextLessonId) {
    const formData = new FormData();
    if (nextLessonId) formData.append('next_lesson_id', nextLessonId);
    const response = await fetch(`/api/v2/lessons/${this.id}/complete/`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${ls('token.access')}`,
        Accept: 'application/json',
      },
      body: formData,
    });
    if (response.status === 200) {
      const data = await response.json();
      return data;
    }
    return false;
  }
}

export default Lesson.register();
