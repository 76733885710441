import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, FormRadio } from 'semantic-ui-react';

const s = {
  h2: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '15px',
    color: '#4D4E58',
  },
  details: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '15px',
    color: '#7E7F8F',
    marginTop: '15px',
  },
};

class HRISSyncConfig extends Component {
  static propTypes = {
    configureHRIS: PropTypes.func,
    state: PropTypes.object,
  };

  render() {
    const { configureHRIS, state } = this.props;
    return (
      <div>

        <h2 style={s.h2}>Removed Users</h2>
        <Form>
          <FormRadio
            label="Delete"
            value="delete"
            checked={state.remove === 'delete'}
            onChange={(e, { value }) => configureHRIS('remove', value)}
          />
          <FormRadio
            label="Deactivate"
            value="deactivate"
            checked={state.remove === 'deactivate'}
            onChange={(e, { value }) => configureHRIS('remove', value)}
          />
          <FormRadio
            label="Ignore"
            value="ignore"
            checked={state.remove === 'ignore'}
            onChange={(e, { value }) => configureHRIS('remove', value)}
          />
        </Form>
        <div style={s.details}>
          This controls the action taken on users which are absent from the HRIS system
          or whose employment status has been marked as inactive.
        </div>
        <h2 style={s.h2}>Group Control</h2>
        <Form>
          <FormRadio
            label="On"
            value
            checked={state.group}
            onChange={(e, { value }) => configureHRIS('group', value)}
          />
          <FormRadio
            label="Off"
            value={false}
            checked={!state.group}
            onChange={(e, { value }) => configureHRIS('group', value)}
          />
        </Form>
        <div style={s.details}>
          This controls whether Hickory group membership will be controlled by
          HRIS system group assignments.
        </div>
        <h2 style={s.h2}>Automatic Invites</h2>
        <Form>
          <FormRadio
            label="On"
            value
            checked={state.invite}
            onChange={(e, { value }) => configureHRIS('invite', value)}
          />
          <FormRadio
            label="Off"
            value={false}
            checked={!state.invite}
            onChange={(e, { value }) => configureHRIS('invite', value)}
          />
        </Form>
        <div style={s.details}>
          This controls whether new users added from the HRIS sync will automatically be sent
          invites to join Hickory. If it is turned off, the learner will still be added, and
          an admin can invite them at any time from the Learners panel.
        </div>
      </div>
    );
  }
}

export default HRISSyncConfig;
