import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bind from 'bind-decorator';
import _ from 'underscore';
import {
  Button, Divider, Dropdown, Icon, Input, List, Segment,
} from 'semantic-ui-react';
import Group from '../../models/group';
import Lesson from '../../models/lesson';
import Tag from '../../models/user_tag';

class GroupCriteria extends Component {
  static propTypes = {
    groupId: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      group: null,
      lessons: [],
      tags: [],
      criteria: [],
    };
  }

  async componentDidMount() {
    const group = await Group.objects().get(this.props.groupId);
    this.setState({
      group,
      criteria: _.clone(group?.criteria?.or) || [],
    });
    const lessons = await Lesson.objects().filtered({ deleted: false, published: true }).all();
    const tags = await Tag.objects().all();
    this.setState({
      lessons,
      tags,
    });
  }

  @bind
  addCriteria(type) {
    const { criteria } = this.state;
    switch (type) {
      case 'creation':
        criteria.push({ type: 'join', delay: 0, check: 'gte' });
        break;
      case 'completion':
        criteria.push({
          type: 'completion', content_id: null, delay: 0, delay_check: 'lte', score: 0, score_check: 'gte',
        });
        break;
      case 'tag':
        criteria.push({
          type: 'attribute', field: 'tags', check: 'icontains', value: null,
        });
        break;
      default:
    }
    this.setState({ criteria });
  }

  @bind
  updateCriteria(index, key, value) {
    const { criteria } = this.state;
    criteria[index][key] = value;
    this.setState({ criteria });
  }

  @bind
  renderCriteria() {
    const {
      criteria, lessons, tags, deleting,
    } = this.state;
    const lessonOptions = lessons.map((l) => ({ key: l.id, value: l.content_id, text: l.name }));
    const tagOptions = tags.map((t) => ({ key: t.name, value: t.name, text: t.name }));
    const renderedCriteria = [];
    criteria.forEach((crit, idx) => {
      if (criteria.length > 1 && idx) {
        renderedCriteria.push(<Divider horizontal>Or</Divider>);
      }
      let renderedCrit = null;
      switch (true) {
        case (crit.type === 'join'):
          renderedCrit = (
            <Segment compact className="group-criterion">
              For the first
              <Input
                type="number"
                step="1"
                value={crit.delay}
                min="0"
                onChange={(e, { value }) => this.updateCriteria(idx, 'delay', Math.floor(Math.max(value, 0)))}
              />
              days after first joining Hickory.
            </Segment>
          );
          break;
        case (crit.type === 'completion'):
          renderedCrit = (
            <Segment compact className="group-criterion">
              <Input
                type="number"
                step="1"
                value={crit.delay}
                min="0"
                onChange={(e, { value }) => this.updateCriteria(idx, 'delay', Math.floor(Math.max(value, 0)))}
              />
              days after completing
              <Dropdown
                placeholder="Select Lesson"
                options={lessonOptions}
                value={crit.content_id}
                search
                selection
                onChange={(e, { value }) => this.updateCriteria(idx, 'content_id', value)}
                loading={!lessons.length}
              />
              with a score of
              <Input
                type="number"
                step="1"
                value={crit.score * 100}
                min="0"
                max="100"
                onChange={(e, { value }) => this.updateCriteria(idx, 'score', Math.min(Math.max(value, 0), 100) / 100)}
              />
              <Dropdown
                options={[
                  { key: 'gte', value: 'gte', text: 'or greater.' },
                  { key: 'lte', value: 'lte', text: 'or less.' },
                ]}
                value={crit.score_check}
                onChange={(e, { value }) => this.updateCriteria(idx, 'score_check', value)}
              />
            </Segment>
          );
          break;
        case (crit.type === 'attribute' && crit.field === 'tags'):
          renderedCrit = (
            <Segment compact className="group-criterion">
              If the user is part of the
              <Dropdown
                placeholder="Select Tag"
                options={tagOptions}
                value={crit.value}
                search
                selection
                onChange={(e, { value }) => this.updateCriteria(idx, 'value', value)}
                loading={!tags.length}
              />
              tag.
            </Segment>
          );
          break;
        default:
      }
      if (renderedCrit) {
        renderedCriteria.push(
          // eslint-disable-next-line react/no-array-index-key
          <List.Item className="criterion-row" key={idx}>
            {renderedCrit}
            {deleting === idx ? (
              <a
                className="delete-icon"
                onClick={this.removeCriteria}
                onMouseOut={() => this.setState({ deleting: null })}
              >
                Delete?
              </a>
            ) : (
              <Icon
                name="trash alternate outline"
                color="grey"
                className="delete-icon"
                onClick={() => this.setState({ deleting: idx })}
              />
            )}
          </List.Item>,
        );
      }
    });
    return renderedCriteria;
  }

  @bind
  removeCriteria() {
    const { criteria, deleting } = this.state;
    criteria.splice(deleting, 1);
    this.setState({ criteria, deleting: null });
  }

  @bind
  async saveCriteria() {
    const group = await Group.objects().update(this.props.groupId, {
      criteria: this.state.criteria.length ? { or: this.state.criteria } : null,
    });
    this.setState({ group });
  }

  render() {
    const { group, criteria } = this.state;
    return (
      <div>
        <div className="row">
          If a user matches any of these criteria, they will be automatically added to this group.
          <Button
            color="green"
            disabled={_.isEqual(group?.criteria, { or: criteria }) || ((group?.criteria === null) && !criteria.length)}
            onClick={this.saveCriteria}
          >Save
          </Button>
        </div>
        <List>
          {this.renderCriteria()}
        </List>
        <Dropdown text="Automatically add a user to this group..." button className="green">
          <Dropdown.Menu>
            <Dropdown.Item
              text="...when the user first joins Hickory"
              onClick={() => this.addCriteria('creation')}
            />
            <Dropdown.Item
              text="...after the user completes a certain lesson"
              onClick={() => this.addCriteria('completion')}
            />
            <Dropdown.Item
              text="...if the user has a certain tag"
              onClick={() => this.addCriteria('tag')}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

export default GroupCriteria;
