import React, { Component } from 'react';
import Radium from 'radium';
import _ from 'underscore';
import bind from 'bind-decorator';
import PropTypes from 'prop-types';
import {
  Dropdown, Modal, Button, Progress,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Group from '@/models/group';
import LessonSchedule from '@/models/lesson_schedule';
import ModelTable from '../Shared/ModelTable';
import Scheduler from '../Shared/Scheduler';
import { GROUP_TYPE_COMPLIANCE, GROUP_TYPE_LEARNING } from '../../constants';

class LessonGroups extends Component {
  static propTypes = {
    lesson: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const { lesson } = this.props;

    this.state = {
      lesson_schedules: [],
      selected_groups: [],
      group_ids_to_assign: [],
      showRemovalConfirmation: false,
      pendingSchedule: {},
      showProgress: false,
      progressPercent: 0,
      selectedLessons: [lesson],
    };
  }

  async componentDidMount() {
    const lesson = this.props.lesson;
    const lessonSchedules = await LessonSchedule.objects().filtered({ lesson_id: lesson.id }).all();
    this.setState({
      lesson_schedules: lessonSchedules,
    });
  }

  @bind
  async unassignLessons() {
    this.setState(
      {
        showProgress: true,
        progressPercent: 0,
        showRemovalConfirmation: false,
      },
      () => {
        const total = this.state.selected_groups.length;
        let completed = 0;

        const actions = _.map(this.state.selected_groups, (info) => {
          const groupId = info[0];
          const schedule = _.findWhere(this.state.lesson_schedules, {
            organizational_group_id: groupId,
          });

          return LessonSchedule.objects()
            .update(schedule.id, { deleted: true })
            .then(() => {
              completed += 1;
              this.setState({ progressPercent: (completed / total) * 100.0 });
            });
        });
        Promise.all(actions).then(() => {
          this.setState({
            showProgress: false,
            selected_groups: [],
          });
          this.modelTableUpdateMethod();
        });
      },
    );
  }

  @bind
  confirmLessonRemoval(groupIds) {
    if (!groupIds || groupIds.length === 0) {
      return;
    }
    this.setState({
      showRemovalConfirmation: true,
      selected_groups: groupIds,
    });
  }

  @bind
  assignLesson() {
    this.setState({
      showGroupAssignment: true,
      assignmentStep: 1,
      group_ids_to_assign: [],
    });
  }

  @bind
  getUpdateHandler(modelTableUpdateMethod) {
    this.modelTableUpdateMethod = modelTableUpdateMethod;
  }

  @bind
  assignGroupsChanged(groups) {
    const groupIds = _.map(groups, (group) => group.id);
    this.setState({
      group_ids_to_assign: groupIds,
    });
  }

  @bind
  pendingScheduleChanged(schedule) {
    this.setState({
      pendingSchedule: schedule,
    });
  }

  @bind
  async scheduleLesson() {
    const { lesson } = this.props;
    const schedule = this.state.pendingSchedule;

    this.setState(
      {
        showGroupAssignment: false,
        showProgress: true,
        progressPercent: 0,
      },
      () => {
        const total = this.state.group_ids_to_assign.length;
        let completed = 0;
        const actions = _.map(this.state.group_ids_to_assign, (groupId) => {
          const scheduleData = {
            organizational_group_id: groupId,
            lesson_id: lesson.id,
            start_date: schedule.start_date,
            start_date_tz: schedule.start_date_tz,
            continuous_reviews: schedule.continuous_reviews,
            review_frequency: schedule.review_frequency,
            review_frequency_type: schedule.review_frequency_type,
            end_date: schedule.end_date,
            retake_threshold: schedule.retake_threshold,
          };
          if (schedule.drip_enabled) {
            scheduleData.requirements = { time_delay: schedule.drip_delay };
          }
          return LessonSchedule.objects()
            .create(scheduleData)
            .then(() => {
              completed += 1;
              this.setState({ progressPercent: (completed / total) * 100.0 });
            });
        });
        Promise.all(actions).then(() => {
          this.setState({ showProgress: false }, this.modelTableUpdateMethod);
        });
      },
    );
  }

  render() {
    const { lesson } = this.props;
    const selectedGroups = this.state.selected_groups;

    let groupName = '';
    if (selectedGroups.length === 1) {
      groupName = selectedGroups[0][1];
    }
    const groupDescription = selectedGroups.length > 1 ? `${selectedGroups.length} groups` : `the "${groupName}" group`;

    const columnData = [
      {
        name: 'Group Name',
        sort: true,
        search: true,
        key: [
          'organizational_group_id',
          'group__name',
          'group__group_type',
          (id, name, groupType) => {
            if (groupType === GROUP_TYPE_COMPLIANCE) {
              return <Link to="/app/v2/compliance/">{name}</Link>;
            }
            return <Link to={`/app/v2/groups/${id}`}>{name}</Link>;
          },
        ],
      },
      {
        name: 'Lesson Status',
        sort: true,
        key: [
          'start_date',
          (startDate) => {
            if (startDate) {
              if (moment(startDate).isBefore(moment())) {
                return 'Active';
              }
              return 'Scheduled';
            }
            return 'Available';
          },
        ],
      },
      {
        name: 'Learners',
        sort: true,
        key: 'organizational_group_summary__learner_count',
      },
      {
        name: 'Lessons',
        key: 'organizational_group_summary__lesson_count',
      },
      {
        name: 'Actions',
        key: [
          'organizational_group_id',
          'group__name',
          (groupId, name) => (
            <Dropdown icon="ellipsis vertical" floating labeled className="icon">
              <Dropdown.Menu>
                <Dropdown.Item
                  text="Remove From Group"
                  onClick={() => {
                    this.confirmLessonRemoval([[groupId, name]]);
                  }}
                />
              </Dropdown.Menu>
            </Dropdown>
          ),
        ],
      },
    ];
    return (
      <div>
        <ModelTable
          title="Groups"
          model={LessonSchedule}
          join={[
            'group__name',
            'group__group_type',
            'organizational_group_summary__learner_count',
            'organizational_group_summary__lesson_count',
          ]}
          columns={columnData}
          defaultSort={['group__name', 'dsc']}
          select
          baseFilter={{ lesson_id: lesson.id, deleted: false }}
          updateHandler={this.getUpdateHandler}
        >
          <ModelTable.Actions>
            <ModelTable.Action
              name="Remove from Group"
              method={(objs) => {
                const ids = objs.map((obj) => [obj.organizational_group_id, obj.group__name]);
                this.confirmLessonRemoval(ids);
              }}
              requireSelected
            />
            <ModelTable.Action name="Add to Group(s)" method={this.assignLesson} />
          </ModelTable.Actions>
        </ModelTable>

        <Modal open={this.state.showRemovalConfirmation}>
          <Modal.Header>Confirm Action</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <p>
                Are you sure you wish to remove the lesson
                {' '}
                <b>{lesson.name}</b>
                {' '}
                from
                {' '}
                {groupDescription}
                ?
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                this.setState({
                  showRemovalConfirmation: false,
                });
              }}
            >
              Cancel
            </Button>
            <Button content="Remove" color="red" onClick={this.unassignLessons} />
          </Modal.Actions>
        </Modal>

        <Modal open={this.state.showGroupAssignment}>
          <Modal.Header>Assign Lesson</Modal.Header>
          <Modal.Content image>
            {this.state.assignmentStep === 1 ? (
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <p>
                  Please select which group(s) you&apos;d like to schedule this lesson into, and
                  click &quot;next&quot;
                </p>
                <ModelTable
                  model={Group}
                  columns={[
                    {
                      name: 'Group Name',
                      search: true,
                      key: 'name',
                    },
                  ]}
                  defaultSort={['name', 'asc']}
                  selectionChanged={this.assignGroupsChanged}
                  select
                  baseFilter={{
                    id__not: _.map(this.state.lesson_schedules, (s) => s.organizational_group_id),
                    group_type: GROUP_TYPE_LEARNING,
                    deleted: false,
                  }}
                />
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Scheduler
                  scheduleChanged={this.pendingScheduleChanged}
                  lessonTypes={this.state.selectedLessons.map((l) => l.lesson_type)}
                />
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              content={this.state.assignmentStep === 1 ? 'Cancel' : 'Back'}
              onClick={() => {
                if (this.state.assignmentStep === 1) {
                  this.setState({ showGroupAssignment: false });
                } else {
                  this.setState({
                    assignmentStep: 1,
                    group_ids_to_assign: [],
                  });
                }
              }}
            />
            <Button
              content={this.state.assignmentStep === 1 ? 'Next' : 'Schedule'}
              disabled={this.state.group_ids_to_assign.length === 0}
              color="green"
              onClick={() => {
                if (this.state.assignmentStep === 1) {
                  this.setState({ assignmentStep: 2 });
                } else {
                  this.scheduleLesson();
                }
              }}
            />
          </Modal.Actions>
        </Modal>

        <Modal open={this.state.showProgress}>
          <Modal.Header>Working...</Modal.Header>
          <Modal.Content image>
            <Progress
              percent={this.state.progressPercent}
              indicating
              style={{ margin: '10px', width: '100%' }}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default Radium(LessonGroups);
