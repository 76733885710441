import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import bind from 'bind-decorator';
import { Input, Checkbox, Dropdown } from 'semantic-ui-react';
import moment from 'moment-timezone';
import { DateInput, TimeInput } from 'semantic-ui-calendar-react';
import User from '@/models/user';
import {
  CARD,
  ARTICLE,
  VIDEO,
  Features,
} from '../../constants';

const s = {
  inline: {
    whiteSpace: 'nowrap',
  },
};

class Scheduler extends Component {
  static propTypes = {
    scheduleChanged: PropTypes.func,
    lessonTypes: PropTypes.array,
  };

  constructor(props) {
    super(props);

    let continuousReviewsAvailable = true;
    let retakeOptionAvailable = true;
    this.props.lessonTypes.forEach((lessonType) => {
      if (lessonType !== CARD) {
        continuousReviewsAvailable = false;
      }
      if (lessonType === ARTICLE || lessonType === VIDEO) {
        retakeOptionAvailable = false;
      }
    });

    this.state = {
      pendingStartTime: '12:00 AM',
      pendingStartDate: moment().format('MM/DD/YYYY'),
      delay: 3,
      setDrip: false,
      scheduled_review: 12,
      scheduled_score: 60,
      allowRetakes: false,
      retakeOptionAvailable,
      scheduled_continuous_reviews: continuousReviewsAvailable,
      continuous_reviews_available: continuousReviewsAvailable,
      reviewFrequencyType: 2,
      reviewUntilType: 'months',
      lateAfter: 3,
    };
  }

  @bind
  async componentDidMount() {
    // inform the parent component of our default state
    const me = await User.me();
    this.setState({
      me,
    }, this.updateParent());
  }

  @bind
  toggleDrip() {
    this.setState(
      {
        setDrip: !this.state.setDrip,
      },
      this.updateParent,
    );
  }

  @bind
  toggleContinuousReviews() {
    this.setState(
      {
        scheduled_continuous_reviews: !this.state.scheduled_continuous_reviews,
      },
      this.updateParent,
    );
  }

  @bind
  toggleReviewExpiry() {
    this.setState(
      {
        reviewsExpire: !this.state.reviewsExpire,
      },
    );
  }

  @bind
  toggleAllowRetakes() {
    this.setState(
      {
        allowRetakes: !this.state.allowRetakes,
      },
      this.updateParent,
    );
  }

  @bind
  updateParent() {
    const { reviewsExpire, reviewUntil, reviewUntilType } = this.state;
    const dateTimeStr = `${this.state.pendingStartDate} ${this.state.pendingStartTime}`;
    const dateTimeFormat = 'MM/DD/YYYY hh:mm A';
    const startTime = moment(dateTimeStr, dateTimeFormat);
    let endReviewsDate = null;
    if (reviewsExpire && reviewUntil) {
      endReviewsDate = moment().add(reviewUntil, reviewUntilType);
    }
    let retakeThreshold = parseInt(this.state.scheduled_score) / 100.0;
    if (retakeThreshold > 1) {
      retakeThreshold = 1;
    } else if (retakeThreshold < 0) {
      retakeThreshold = 0;
    }

    const schedule = {
      drip_enabled: this.state.setDrip,
      drip_delay: this.state.delay,
      start_date: startTime,
      start_date_tz: moment.tz.guess(),
      continuous_reviews: this.state.scheduled_continuous_reviews,
      review_frequency: this.state.scheduled_review,
      review_frequency_type: this.state.reviewFrequencyType,
      end_date: endReviewsDate,
      retake_threshold: this.state.allowRetakes ? retakeThreshold : 0,
      late_after: this.state.lateAfter,
    };
    if (this.props.scheduleChanged) {
      this.props.scheduleChanged(schedule);
    }
  }

  render() {
    const {
      me, retakeOptionAvailable, reviewFrequencyType, lateAfter, reviewUntilType,
    } = this.state;
    const showAdvancedScheduling = me
                                && me.waffle_flags.includes(Features.ADVANCED_SCHEDULING)
                                && !this.state.settingPath;

    return (
      <div>
        <DateInput
          onChange={(e, { value }) => {
            this.setState({ pendingStartDate: value }, this.updateParent);
          }}
          value={this.state.pendingStartDate}
          closable
          dateFormat="MM/DD/YYYY"
          minDate={moment().format('MM/DD/YYYY')}
          inlineLabel
          label="Start Date: "
        />
        <TimeInput
          onChange={(e, { value }) => {
            this.setState({ pendingStartTime: value }, this.updateParent);
          }}
          value={this.state.pendingStartTime}
          inlineLabel
          label="Start Time: "
          timeFormat="AMPM"
        />
        <p>
          <em>NOTE: Scheduled lesson start times are localized on a per-learner basis.</em>
        </p>
        <p>
          <em>
            For example, a lesson scheduled to start at 10am will roll out to learners at 10am
            {' '}
            <strong>Eastern</strong>
            {' '}
            for users in Eastern time zone, and at 10am
            {' '}
            <strong>Central</strong>
            {' '}
            to users in Central time zone.
          </em>
        </p>
        { showAdvancedScheduling && (
          <div>
            <table style={{ width: '80%' }}>
              <tbody>
                <tr>
                  <td>Mark this lesson as late:&nbsp;
                    <Input
                      style={{ maxWidth: '60px' }}
                      value={lateAfter}
                      onChange={(e, { value }) => {
                        this.setState({ lateAfter: value.match(/\d*/)[0] }, this.updateParent);
                      }}
                    />
                    {' '}
                    days after it is assigned
                  </td>
                </tr>
                <tr>
                  <td>
                    <Checkbox label="Drip Schedule" onChange={this.toggleDrip} toggle />
                  </td>
                  <td />
                </tr>
                {this.state.setDrip && (
                  <tr>
                    <td>Delay:&nbsp;</td>
                    <td>
                      <Input
                        value={this.state.delay}
                        onChange={(e, { value }) => {
                          this.setState({ delay: value.match(/\d*/)[0] }, this.updateParent);
                        }}
                      />
                      {' '}
                      days after joining group
                    </td>
                  </tr>
                )}
                {this.state.setDrip && (
                  <tr>
                    <td colSpan="2">
                      <em>
                        NOTE: Lessons will
                        {' '}
                        <strong>not</strong>
                        {' '}
                        be available to each individual
                        learner until
                        {' '}
                        <strong>after</strong>
                        {' '}
                        the lesson&apos;s schedule start date
                        has passed,
                        {' '}
                        <strong>as well as</strong>
                        {' '}
                        the specified number of days has
                        elapsed since they&apos;ve joined this group.
                      </em>
                    </td>
                  </tr>
                )}
                {this.state.continuous_reviews_available ? (
                  <tr>
                    <td>
                      <Checkbox
                        label="Continuous Learning"
                        onChange={this.toggleContinuousReviews}
                        checked={this.state.scheduled_continuous_reviews}
                        toggle
                      />
                    </td>
                    <td />
                  </tr>
                ) : null}
                {this.state.scheduled_continuous_reviews ? null : (
                  <tr>
                    <td>Review entire lesson every:</td>
                    <td style={s.inline}>
                      <Input
                        key={1}
                        style={{ maxWidth: '60px' }}
                        value={this.state.scheduled_review}
                        onChange={(e, { value }) => {
                          this.setState(
                            { scheduled_review: value.match(/\d*/)[0] },
                            this.updateParent,
                          );
                        }}
                      />
                      <Dropdown
                        compact
                        selection
                        onChange={(e, { value }) => {
                          this.setState({ reviewFrequencyType: value }, this.updateParent);
                        }}
                        value={reviewFrequencyType}
                        options={[
                          {
                            key: 1,
                            text: 'Days',
                            value: 1,
                          },
                          {
                            key: 2,
                            text: 'Months',
                            value: 2,
                          },
                        ]}
                      />
                      {' '}
                      after completion
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <Checkbox
                      label="Turn off reviews after:"
                      onChange={this.toggleReviewExpiry}
                      checked={this.state.reviewsExpire}
                      toggle
                    />
                  </td>
                  <td style={s.inline}>
                    {this.state.reviewsExpire && (
                      <>
                        <Input
                          key={0}
                          style={{ maxWidth: '60px' }}
                          value={this.state.reviewUntil}
                          onChange={(e, { value }) => {
                            this.setState(
                              { reviewUntil: value.match(/\d*/)[0] },
                              this.updateParent,
                            );
                          }}
                        />
                        <Dropdown
                          compact
                          selection
                          onChange={(e, { value }) => {
                            this.setState({ reviewUntilType: value }, this.updateParent);
                          }}
                          value={reviewUntilType}
                          options={[
                            {
                              key: 1,
                              text: 'Days',
                              value: 'days',
                            },
                            {
                              key: 2,
                              text: 'Months',
                              value: 'months',
                            },
                          ]}
                        />
                      </>
                    )}
                  </td>
                </tr>
                { retakeOptionAvailable ? (
                  <tr>
                    <td>
                      <Checkbox label="Allow retakes" onChange={this.toggleAllowRetakes} toggle />
                    </td>
                    <td />
                  </tr>
                ) : null }
                {this.state.allowRetakes ? (
                  <tr>
                    <td>Require a score of:</td>
                    <td>
                      <Input
                        value={this.state.scheduled_score}
                        onChange={(e, { value }) => {
                          this.setState(
                            { scheduled_score: value.match(/\d*/)[0] },
                            this.updateParent,
                          );
                        }}
                      />
                      {' '}
                      percent or higher to mark as completed
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default Radium(Scheduler);
