import React, { useCallback } from 'react';
import { useMergeLink } from '@mergeapi/react-merge-link';
import PropTypes from 'prop-types';
import ls from 'local-storage';

const s = {
  appIcon: {
    width: '96px',
    height: '96px',
  },
  integrationIcon: {
    display: 'flex',
    flexDirection: 'column',
    width: '96px',
    cursor: 'pointer',
  },
  integrationName: {
    textAlign: 'center',
  },
};

const MergeLinkButton = function MergeLinkButton(props) {
  const onSuccess = useCallback(async (publicToken) => {
    await fetch(`/api/v2/merge/`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${ls('token.access')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        public_token: publicToken,
      }),
    });
  }, []);
  const { open, isReady } = useMergeLink({
    linkToken: props.token,
    onSuccess,
  });
  return (
    <a disabled={!isReady} onClick={open} style={s.integrationIcon}>
      <img src="/static/v2/frontend/merge_api_logo.jpg" style={s.appIcon} />
      <strong style={s.integrationName}>Merge HRIS Integration</strong>
    </a>
  );
};

MergeLinkButton.propTypes = {
  token: PropTypes.string,
};

export default MergeLinkButton;
