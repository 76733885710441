import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bind from 'bind-decorator';
import {
  Button, Dropdown, Header, Icon, Input, Segment, Table, Modal, Progress,
} from 'semantic-ui-react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import pluralize from 'pluralize';
import LessonSchedule from '@/models/lesson_schedule';
import Lesson from '@/models/lesson';
import Scheduler from '../Shared/Scheduler';

const FREQUENCY = ['day', 'month'];

class GroupLessonCard extends Component {
  static propTypes = {
    schedule: PropTypes.object,
    refresh: PropTypes.func,
    confirmRemoveFromGroup: PropTypes.func,
    confirmToggleReview: PropTypes.func,
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      scheduling: false,
      showEditWarningModal: false,
      showCloneModal: false,
      clonedLessonName: "",
      clone: null,
      showCloneProgress: false,
      cloning_progress: 0,
    };
  }

  @bind
  pendingScheduleChanged(schedule) {
    this.setState({
      pendingSchedule: schedule,
    });
  }

  @bind
  async scheduleLesson() {
    const { pendingSchedule } = this.state;
    const schedule = {
      start_date: pendingSchedule.start_date,
      start_date_tz: pendingSchedule.start_date_tz,
      continuous_reviews: pendingSchedule.continuous_reviews,
      review_frequency: pendingSchedule.review_frequency,
      review_frequency_type: pendingSchedule.review_frequency_type,
      end_date: pendingSchedule.end_date,
      retake_threshold: pendingSchedule.retake_threshold,
      late_after: pendingSchedule.late_after,
    };
    if (pendingSchedule.drip_enabled) {
      schedule.requirements = { time_delay: pendingSchedule.drip_delay };
    }

    await LessonSchedule.objects().update(this.props.schedule.id, schedule);
    this.props.refresh();
  }

  @bind
  cloneLesson(schedule) {
    this.setState({
      showEditWarningModal: null,
      showCloneModal: true,
      clonedLessonName: `CLONE  ${schedule.lesson__name}`,
      clone: schedule,
    });
  }

  @bind
  async doCloneLesson() {
    this.setState({
      showCloneModal: false,
      showCloneProgress: true,
      cloning_progress: 0,
    });
    const cloneFromSchedule = this.state.clone;
    const cloneFrom = await Lesson.objects().filtered({ id: cloneFromSchedule.lesson_id }).first();
    const dolly = await Lesson.objects().create({
      name: this.state.clonedLessonName,
      cloned_from_id: cloneFrom.lesson_id,
    });
    this.waitForClone(dolly);
  }

  @bind
  waitForClone(dolly) {
    this.setState({ cloning_progress: this.state.cloning_progress + 1 });
    setTimeout(() => {
      if (this.state.cloning_progress >= 100) {
        this.setState({ showCloneProgress: false });
        this.props.history.push(`/app/v2/lessons/${dolly.id}/editor`);
      } else {
        this.waitForClone(dolly);
      }
    }, 100);
  }

  @bind
  menuOptionsForLesson(schedule) {
    return (
      <Dropdown text="" direction="left">
        <Dropdown.Menu>
          <Dropdown.Item
            text="Edit"
            onClick={() => {
              const editable = schedule.lesson__available_to_edit;
              if (editable) {
                this.props.history.push(`/app/v2/lessons/${schedule.lesson_id}/`);
              } else {
                this.setState({ showEditWarningModal: schedule });
              }
            }}
          />
          <Dropdown.Item
            text="Preview"
            onClick={() => {
              this.props.history.push(`/app/v2/lessons/${schedule.lesson_id}/player/preview`);
            }}
          />
          <Dropdown.Divider />
          { schedule.start_date ? (
            <Dropdown.Item
              text={
                    schedule.reviews_enabled ? 'Turn Off Reviews' : 'Turn On Reviews'
                  }
              onClick={() => {
                this.props.confirmToggleReview(schedule);
              }}
            />
          ) : null }

          <Dropdown.Item
            text="Remove From Group"
            onClick={() => {
              this.props.confirmRemoveFromGroup(schedule, true);
            }}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  @bind
  showScheduling(schedule) {
    const { scheduling } = this.state;
    return (
      <div>
        <Scheduler
          scheduleChanged={this.pendingScheduleChanged}
          lessonTypes={[schedule.lesson__lesson_type]}
        />
        <Button onClick={() => this.setState({ scheduling: !scheduling })}>Cancel</Button>
        <Button positive onClick={this.scheduleLesson}>Schedule</Button>
      </div>
    );
  }

  render() {
    const { scheduling } = this.state;
    const { schedule } = this.props;
    const now = new Date();

    return (
      <Segment className="row">
        <div className="hickory-column">
          <Link to={`/app/v2/lessons/${schedule.lesson_id}`}>
            <Header as="h2" color="blue">{schedule.lesson__name}</Header>
          </Link>
          <span>Last updated {moment(schedule.lesson__updated_at).format('MMMM Do [at] h:mm a')}.</span>
        </div>
        {!schedule.start_date && (scheduling
          ? this.showScheduling(schedule)
          : (
            <div>
              <Button positive onClick={() => this.setState({ scheduling: !scheduling })}>Schedule</Button>
              {this.menuOptionsForLesson(schedule)}
            </div>
          )
        )}
        {schedule.start_date && (
        <Table className="hickory-borderless" style={{ maxWidth: '50%' }}>
          <Table.Body>
            <Table.Row>
              <Table.Cell textAlign="center">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    {moment(schedule.start_date).isAfter(now) ? (
                      <p style={{ color: 'gray' }}>STARTING</p>
                    ) : (
                      <p style={{ color: 'green' }}>STARTED</p>
                    )}
                    {schedule.start_date_tz === 'UTC'
                      ? moment(schedule.start_date).format('MMMM Do YYYY, hh:mm a')
                      : moment(schedule.start_date)
                        .tz(schedule.start_date_tz)
                        .format('MMMM Do YYYY, hh:mm a')}
                    {schedule.reviews_enabled ? (
                      <p />
                    ) : (
                      <Icon name="calendar times outline" />
                    )}

                    {!schedule.continuous_reviews && (
                    <div style={{ textAlign: "left" }}>
                      Retake: every {schedule.review_frequency} {pluralize(
                      FREQUENCY[schedule.review_frequency_type - 1],
                    )}
                    </div>
                    )}
                    {schedule.retake_threshold > 0 && (
                    <div style={{ textAlign: "left" }}>
                      Pass: {parseInt(schedule.retake_threshold * 100.0)}%
                    </div>
                    )}
                  </div>
                  {schedule.requirements && 'time_delay' in schedule.requirements && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                          &nbsp;
                    <span style={{ fontSize: 'x-large' }}>&</span>
                    <Header as="h2" style={{ margin: '10px' }}>
                      {schedule.requirements.time_delay}
                    </Header>
                    Days after Learner
                    <br />
                    is added to group
                  </div>
                  )}
                </div>
              </Table.Cell>
              {schedule.start_date && (
              <Table.Cell textAlign="center">
                {schedule.end_date ? (
                  <div>
                    <p style={{ color: 'red' }}>END</p>
                    {moment(schedule.end_date).format('MMMM Do YYYY')}
                  </div>
                ) : (
                  <p
                    style={{
                      color: moment(schedule.start_date).isAfter(now) ? 'gray' : 'green',
                    }}
                  >
                    {moment(schedule.start_date).isAfter(now) ? 'QUEUED' : 'LIVE'}
                  </p>
                )}
              </Table.Cell>
              )}
              <Table.Cell>
                {this.menuOptionsForLesson(schedule)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        )}

        <Modal
          open={this.state.showEditWarningModal}
          onClose={() => this.setState({ showEditWarningModal: false })}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>You are editing a scheduled lesson</Modal.Header>
          <Modal.Content>
            While you will still be able to edit the contents of cards, you will be unable to add or
            remove cards.
            {' '}
            <br />
            If you need to do so, please
            {' '}
            <strong>Clone</strong>
            {' '}
            the lesson and edit the cloned copy.
            {' '}
            <br />
            Otherwise, continue on to
            {' '}
            <strong>Edit</strong>
            {' '}
            the lesson
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              onClick={() => {
                this.cloneLesson(this.state.showEditWarningModal);
              }}
            >
              Clone
            </Button>
            <Button
              positive
              onClick={() => {
                this.props.history.push(`/app/v2/lessons/${this.state.showEditWarningModal.lesson_id}/editor`);
              }}
            >
              Edit
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showCloneModal}
          onClose={() => {
            this.setState({ showCloneModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Clone Lesson</Modal.Header>
          <Modal.Content>
            Name:
            <br />
            <Input
              value={this.state.clonedLessonName}
              style={{ width: '100%' }}
              onChange={(e, { value }) => this.setState({ clonedLessonName: value })}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showCloneModal: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="green"
              onClick={() => {
                this.doCloneLesson();
              }}
            >
              Clone
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal open={this.state.showCloneProgress} style={{ width: '500px' }}>
          <Modal.Header>Cloning Lesson</Modal.Header>
          <Modal.Content>
            <Progress percent={this.state.cloning_progress} indicating />
          </Modal.Content>
          <Modal.Actions />
        </Modal>
      </Segment>

    );
  }
}

export default GroupLessonCard;
